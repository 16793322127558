import * as locales from "@/locales/index"
import * as ownerLocales from "@/modules/Owner/locales/index"
import * as petLocales from "@/modules/Pet/locales/index"
import * as pricingLocales from "@/modules/Pricing/locales/index"
import * as recapLocales from "@/modules/Recap/locales/index"
import * as welcomeLocales from "@/modules/Welcome/locales/index"
import { type InitOptions } from "i18next"

export const fallbackLng = "fr"
export const languages = [fallbackLng] as const
export type Language = (typeof languages)[number]

export function getOptions(lng = fallbackLng): InitOptions {
  return {
    debug: false,

    supportedLngs: languages,
    fallbackLng,
    lng,

    resources: {
      fr: {
        ...locales.fr,
        ...petLocales.fr,
        ...ownerLocales.fr,
        ...pricingLocales.fr,
        ...recapLocales.fr,
        ...welcomeLocales.fr,
      },
    },

    parseMissingKeyHandler: (key) => {
      console.error(`i18n key "${key}" not found.`)
      // Dramatic emojis only please
      return `🧨🤦‍♂️ MISSING KEY: ${key} 🤦‍♂️🧨`
    },

    interpolation: {
      escapeValue: false,
    },
  }
}
