export const SANDBOX = process.env.NEXT_PUBLIC_PRODUCTION !== "true"

const staggingConfig = {
  API_DOMAIN: "dalma.dev",
  PRODUCT: "dalma-latest",
  STRIPE_PUB_KEY:
    "pk_test_51NOKnzDEL6nINIqvp0nKwvhV31l2mGARiHjz47aIp0s7EqRkshDO7t52419KE3WN6979hPUHEPmbCjOW3NyZ8Vdw00sGRx9285",
  BREEDS_BFF_URL: "https://breeds.dalma.dev/latest",
  BREEDS_BFF_VERSION: "v1",
  BREEDS_BFF_KEY: "latest-breeds-fr-bff-is-awesome",
  CALENDLY_URL:
    "https://calendly.com/expert-dalma/votre-abonnement-dalma?utm_source=website&utm_term=CTA_top_right",
}

const prodConfig = {
  API_DOMAIN: "dalma.tech",
  PRODUCT: "dalma",
  STRIPE_PUB_KEY:
    "pk_live_51II9kbF5i98BakySdxETLJWq2OZb5hY2DxL0L9eZoRtO9e1YQySiZuxoHPHfuPUSU5TPXekgEFFLF9Ufenda20yy00hcNJo0fE",
  BREEDS_BFF_URL: "https://breeds.dalma.tech/prod",
  BREEDS_BFF_VERSION: "v1",
  BREEDS_BFF_KEY: "prod-breeds-fr-bff-is-awesome",
  CALENDLY_URL:
    "https://calendly.com/expert-dalma/votre-abonnement-dalma?utm_source=website&utm_term=CTA_top_right",
}

export const config = SANDBOX ? staggingConfig : prodConfig

export const LEAD_API_KEY = SANDBOX
  ? "eQr5rmytxs1KpebkPcxym2YUxIkpBO2O8M8wqbgh"
  : "ffsS0p9HCn9JDykdfDklS460xhNwHjmT1Kk0TfTm"

export const googleApiKey = SANDBOX
  ? "AIzaSyDcixeeF7RtO-sGkkRHocOUMpdgMUtvZbE"
  : "AIzaSyDcixeeF7RtO-sGkkRHocOUMpdgMUtvZbE"

export const TRACKJS_TOKEN = ""

export const legal = {
  GUARANTEES_TABLE_URL:
    "https://editique.dalma.co/formule_dalma/latest/Tableau_de_garanties_Dalma_fr.pdf",
}
