import { Utms } from "../types/global-store"

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const runsOnServerSide = typeof window === "undefined"

/**
 * This function is a copy/paste from the french subscription flow
 * @returns a random user id
 */
export function generateUserId() {
  let userId = "DAL_" + Math.random().toString(36).substring(2, 4)
  userId += "_" + Date.now().toString()

  return userId
}

//https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
export function generatePetId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

/**
 * Source: https://grafikart.fr/tutoriels/drapeau-emoji-fonction-2152
 * @param code fr, en, de...
 * @returns the emoji flag (🇫🇷, 🇬🇧, 🇩🇪)
 */
export function isoToEmoji(code: string) {
  return code
    .split("")
    .map((letter) => (letter.charCodeAt(0) % 32) + 0x1f1e5)
    .map((emojiCode) => String.fromCodePoint(emojiCode))
    .join("")
}

// Source: https://www.freecodecamp.org/news/javascript-debounce-example/
export function debounce(func: (...args: any[]) => any, timeout = 100) {
  let timer: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(args)
    }, timeout)
  }
}

// Source: https://www.30secondsofcode.org/js/s/element-is-visible-in-viewport/
export function matchIsElementInTheViewport(el: Element, partiallyVisible = false) {
  const { top, left, bottom, right } = el.getBoundingClientRect()
  const { innerHeight, innerWidth } = window
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
}

type MaybeQuoteID = { context?: { meta?: { quote_id?: string } } }

export function getCurrentQuoteID() {
  if (runsOnServerSide) return

  const stateAsString = localStorage.getItem("flow-state") ?? "{}"
  const state = JSON.parse(stateAsString) as MaybeQuoteID

  return state?.context?.meta?.quote_id
}

export function hasUtm(utms: Utms) {
  return utms.utm_source ||
    utms.utm_medium ||
    utms.utm_campaign ||
    utms.utm_content ||
    utms.utm_term ||
    utms.compclid ||
    utms.at_gd ||
    utms.advertiser_id ||
    utms.channel_id ||
    utms.market ||
    utms.click_id ||
    utms.click_ref
    ? true
    : false
}
