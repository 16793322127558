import { config } from "@/shared/utils/config"
import ky from "ky"
import { toast } from "react-hot-toast"
import Stripe from "stripe"

export const checkoutAPI = ky.create({
  prefixUrl: `https://checkout.${config.API_DOMAIN}/${config.PRODUCT}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  timeout: 60000,
})

/**
 * URL: `/create-checkout-session`
 *
 * METHOD: `POST`
 */
export async function stripeCheckout(quoteId: string, petIndexes: number[]) {
  return await checkoutAPI
    .post("create-checkout-session", {
      json: {
        type: "checkout.create",
        quote_id: quoteId,
        metadata: {
          pets: petIndexes,
        },
      },
    })
    .then((response): Promise<Stripe.Checkout.Session> => {
      return response.json()
    })
    .then((session) => {
      const stripe = window.Stripe(config.STRIPE_PUB_KEY, { locale: "fr" })
      return stripe.redirectToCheckout({
        sessionId: session.id,
      })
    })
    .then((result) => {
      // In case `redirectToCheckout` fails due to a browser or network error
      if (result.error.message) {
        toast.error(result.error.message)
      }
    })
}
