import posthog from "posthog-js"

import { Pet } from "../types/global-store"
import { SANDBOX } from "./config"
import { gtag } from "./gtm"
import { capitalizeFirstLetter } from "./string"

export function trackClick(eventName: string, eventProperties: Object | null = null) {
  gtag({
    event: "amplitude_click",
    eventType: eventName,
    eventProperties: {
      ...eventProperties,
      livemode: !SANDBOX,
    },
  })
}

export function trackMeetingClickInfoAddress() {
  posthog.capture("meeting_click_info_address", {
    url: window.location.href,
  })
  trackClick("meeting_click_info_address", {
    url: window.location.href,
  })
}

export function trackMeetingClickPricing() {
  posthog.capture("meeting_click_pricing", {
    url: window.location.href,
  })
  trackClick("meeting_click_pricing", {
    url: window.location.href,
  })
}

export function trackMeetingClickTopbanner() {
  posthog.capture("meeting_click_topbanner", {
    url: window.location.href,
  })
  trackClick("meeting_click_topbanner", {
    url: window.location.href,
  })
}

export function trackAppDownload() {
  posthog.capture("app_download", {
    url: window.location.href,
  })
  trackClick("app_download", {
    url: window.location.href,
  })
}

export function trackLeadAttribution(clickId: string) {
  posthog.capture("lead_attribution", {
    ClickID: clickId,
  })
  gtag({
    event: "lead_attribution",
    ClickID: clickId,
  })
}

export function formatConversionEventId(petType: Pet["type"]) {
  return `${capitalizeFirstLetter(petType)}-OP`
}

export function trackWelcomeValidation() {
  posthog.capture("Validation-paiement")
  gtag({
    event: "Validation-paiement",
  })
}

export function trackMeetingClickNavbar() {
  posthog.capture("meeting_click_navbar", {
    url: window.location.href,
  })
  trackClick("meeting_click_navbar", {
    url: window.location.href,
  })
}

export function trackMeetingClickCancellation() {
  posthog.capture("cancellation_cta_click", {
    url: window.location.href,
  })
  trackClick("cancellation_cta_click", {
    url: window.location.href,
  })
}

export function trackMeetingClickPetBirthdate() {
  posthog.capture("meeting_click_petBirthdate", {
    url: window.location.href,
  })
  trackClick("meeting_click_petBirthdate", {
    url: window.location.href,
  })
}

export function trackMeetingClickPetID() {
  posthog.capture("meeting_click_petID", {
    url: window.location.href,
  })
  trackClick("meeting_click_petID", {
    url: window.location.href,
  })
}

export function trackMeetingClickTooltip() {
  posthog.capture("meeting_click_tooltip", {
    url: window.location.href,
    pricing_block: true,
  })
  trackClick("meeting_click_tooltip", {
    url: window.location.href,
    pricing_block: true,
  })
}

export function trackPrepackages(type: "eco" | "comfort" | "premium" | "optimal") {
  posthog.capture("prepackage", {
    type,
  })
  trackClick("prepackage", {
    type,
  })
}
