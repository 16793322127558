import { Utms } from "@/shared/types/global-store"
import type {
  CreateQuoteResponse,
  PetOldDiseases,
  PricingResponse,
  SyncBackBody,
  SyncBackPet,
} from "@/shared/types/lead-api-types"
import { config, LEAD_API_KEY } from "@/shared/utils/config"
import ky from "ky"

export const leadAPI = ky.create({
  prefixUrl: `https://lead.${config.API_DOMAIN}/${config.PRODUCT}/v2`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    "x-api-key": LEAD_API_KEY,
  },
})

export const v1leadAPI = ky.create({
  prefixUrl: `https://lead.${config.API_DOMAIN}/${config.PRODUCT}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    "x-api-key": LEAD_API_KEY,
  },
})

/**
 * URL: `/quote`
 *
 * METHOD: `PUT`
 */
export function addPet(quoteId = "", pet: SyncBackPet) {
  return leadAPI
    .put(`add_pet/${quoteId}`, {
      json: pet,
    })
    .json<CreateQuoteResponse>()
}

type CreateQuoteBody = {
  pets: {
    idx: number
    name: string
  }[]
  meta?: {
    quote_id?: string
  }
}

/**
 * URL: `/quote`
 *
 * METHOD: `PUT`
 */
export function createQuote(quoteId = "", petIdx = 0) {
  const body: CreateQuoteBody = {
    pets: [
      {
        idx: petIdx,
        name: "pet_name",
      },
    ],
  }

  if (Boolean(quoteId)) {
    body.meta = {
      quote_id: quoteId,
    }
  }

  return leadAPI
    .put("quote", {
      json: body,
    })
    .json<CreateQuoteResponse>()
}

export type QuoteReponse = {
  success: boolean
  data: PetQuote[]
  pet_owner: PetOwner
}

export type Options = {
  death: string
  pack: string
  rate: string
  liability: string
  prevention: string
}

export type Coverage = {
  health_limit: string
  prevention_limit: string
  deductible_limit: string
  death_limit: string
}

export type OptionsCard = {
  optionName: string
  optionPrice: number
}

export type PetQuote = {
  pet_birthday: string
  api_version: string
  subscription_start_at: string
  accept_date: string
  legal_version: string
  accept_quote: boolean
  owner_birthcity: string
  pet_father_race: string
  owner_lastname: string
  payment_status: string
  owner_firstname: string
  payment_platform: string
  owner_email: string
  pet_race: string
  pet_old_diseases: string[]
  pet_name: string
  pet_mother_race: string
  pet_weight: string
  options: Options
  pet_type: string
  owner_birthcountry: string
  pet_uuid: string
  owner_birthday: string
  insurer_cancellation: string
  pet_sexe: string
  pet_uuid_type: string
  quote_id: string
  pet_live_with_other: string
  coverage: Coverage
  contract_id: string
  pet_age_month: number
  address: Address
  product_dalma: string
  versions: Versions
  after_discount: number
  user_id: string
  accepted_formula: string
  contract_uuid: string
  accept_marketing_callback: boolean
  contract_pdf: string
  start_date: string
  owner_phone: string
  pet_category: string
  pet_age_year: number
  price: number | string
  pet_idx: number
  optionsCard: OptionsCard[]
  original_price?: string
  discount_value?: number
  pet_health: {
    status: "healthy" | "not_healthy"
    old_diseases: (PetOldDiseases | string)[]
  }
  marketing?: {
    referrer_details?: string
    referrer_type?: string
  }
  utm_params: UtmParams
  raf_code?: string
  from_lp?: boolean
  ab_tests?: {
    prepackages: {
      variant: "A" | "B" | "default"
    }
  }
}

export type UtmParams = {
  utm_term: string
  utm_comparateur: string
  gclid: string
  utm_campaign: string
  utm_medium: string
  utm_source: string
  utm_content: string
}

export type Referrer = {
  referrer_url: string
  referral: string
}

export type Address = {
  zipcode: string
  country: string
  street_nb: string
  city: string
  street_name: string
}

export type Versions = {}

export type PetOwner = {
  owner_email: string
  owner_lastname: string
  owner_firstname: string
  owner_birthcity: string
  owner_birthday: string
  address: Address2
  owner_phone: string
}

export type Address2 = {
  zipcode: string
  country: string
  street_nb: string
  city: string
  street_name: string
  country_code: "FR"
}

/**
 * URL: `/quote`
 *
 * METHOD: `GET`
 */
export async function getQuote(quoteId: string) {
  const a = await leadAPI.get(`quote/${quoteId}`).json<QuoteReponse>()
  return a
}

/**
 * URL: `/utm`
 *
 * METHOD: `POST`
 */
export function sendUtm(quoteId: string, utmParams: Utms, referrer: string) {
  return v1leadAPI
    .post("utm", {
      json: {
        quote_id: quoteId,
        utm: utmParams,
        referrer: referrer,
      },
    })
    .json()
}

/**
 * URL: `/pet`
 *
 * METHOD: `DELETE`
 */
export function deletePet(quoteId: string, petIdx: number) {
  return leadAPI
    .delete("pet", {
      json: {
        data: {
          quote_id: quoteId,
          pet_idx: petIdx,
        },
      },
    })
    .json()
}

/**
 * URL: `/quote`
 *
 * METHOD: `POST`
 */
export function syncBack(data: SyncBackBody) {
  return leadAPI
    .post("quote", {
      json: {
        ...data,
      },
    })
    .json<{ success: boolean }>()
}

/**
 * URL: `/pricing`
 *
 * METHOD: `GET`
 */
export async function fetchPrices(quoteId: string) {
  const a = await leadAPI.get(`pricing/${quoteId}`).json<PricingResponse>()
  return a
}

/**
 * URL: `/quote-validation`
 *
 * METHOD: `POST`
 */
export async function validateQuote(data: SyncBackBody) {
  return leadAPI.post("quote-validation", { json: data }).json()
}
